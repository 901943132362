<template>
	<section id="login">
    <div class="auth_header">
      <h1>
        be the best
        <small> xanax<span class="a_link">666</span></small>
      </h1>
    </div>

		<div class="auth_form">
      <input
        v-model="form.email"
        type="email"
        placeholder="e-mail"
      />

      <input
        v-model="form.password"
        type="password"
        placeholder="password"
      />

      <div class="form_options">
        need a account?

        <router-link to="/auth/register" class="a_link">
          create account.
        </router-link>
      </div>

      <button
        class="action"
        @click.prevent="this.submitForm()"
      >Login</button>
		</div>
	</section>
</template>

<script>
  import AuthService from '@/services/auth.js';
  import Validator from '@/utils/validator.js';
  import SwalAxios from '@/utils/swal-axios.js';

	export default {
    data: () => {
      return {
        form: {
          email: '',
          password: ''
        }
      }
    },
		methods: {
      submitForm() {
        let data = this.form;

        if (
          !Validator.email(data.email) ||
          !Validator.length(data.password, 6)
        ) {
          return SwalAxios.toast({
            icon: 'error',
            title: 'invalid login form data.'
          });
        }

        AuthService.login(this, data);
      }
    }
	}
</script>

<style scoped>
	#login {
		transform: translateY(35%);
	}

  .auth_header, .auth_form {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .auth_form {
    max-width: 300px;
    margin: 0 auto;
  }

  .auth_header img {
    width: 250px;
    animation: 1.8s infinite alternate kick-effect-i;
  }

  .form_options {
    margin: 5px 0 3px 10px;
    text-align: left;
    font-weight: 500;
  }

  input:last-child {
    margin: 10px 0 0;
  }

  /* resets  */
  input, textarea, select, button {
    outline: none;
    border: 0!important;;
    padding: 7px 20px;
    border-radius: 50px;
    width: 100%;
  }

  button {
    padding: 6.5px 15px!important ;
  }
</style>
